<template>
  <div class="container mx-auto">
    <ChatBot />
  </div>
</template>

<script>
import ChatBot from './components/ChatBot.vue';

export default {
  name: 'App',
  components: {
    ChatBot,
  },
};
</script>
